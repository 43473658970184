<template>
  <n-spin :show="loading">
    <n-card hoverable segmented>
      <template #header>
        <i class="material-icons">person</i>
        <span class="title">{{ user.login }}</span>
      </template>
      <div>Created: {{ moment(user.created).format('DD.MM.YYYY') }}</div>
      <div>Updated: {{ moment(user.updated).format('DD.MM.YYYY') }}</div>
      <template #action>
        <div class="actions">
          <n-popconfirm @positive-click="deleteUser" :disabled="id === user.id">
            <template #trigger>
              <div>
                <n-button
                  type="error"
                  ghost
                  class="button"
                  :disabled="id === user.id"
                >
                  <template #icon>
                    <i class="material-icons material-icons--small">delete</i>
                  </template>
                  Delete
                </n-button>
              </div>
            </template>
            Really?
          </n-popconfirm>
        </div>
      </template>
    </n-card>
  </n-spin>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: mapState({
    id: state => state.id,
  }),
  methods: {
    moment,
    deleteUser() {
      console.log('yyoo');
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  padding: 0 5px;
  vertical-align: middle;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
