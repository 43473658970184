<template>
  <div class="users">
    <div class="search">
      <n-input v-model:value="query" placeholder="Search user..." />
    </div>
    <div class="list">
      <div v-for="item in filteredUsers" :key="item.version" class="item">
        <user-card :user="item"></user-card>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import api from '@/api';
import UserCard from '@/components/UserCard';

export default defineComponent({
  components: { UserCard },
  data() {
    return {
      users: [],
    };
  },
  setup() {
    return {
      query: ref(''),
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(item => {
        return item.login.includes(this.query);
      });
    },
  },
  async beforeRouteEnter(to, from, next) {
    await api
      .get('users')
      .then(response => {
        next(vm => (vm.users = response.data));
      })
      .catch(error => {
        switch (error.response.status) {
          case 401:
            next('/login');
            break;
          default:
            next(`/error/${error.response.status}`);
        }
      });
  },
});
</script>

<style scoped lang="scss">
.search {
  max-width: 500px;
  margin: 20px auto;
}
.list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  flex: 0 1 200px;
  margin: 10px;
}
</style>
